<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('게임실행'),
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'play',
      response: '',
      body: {
        vendorKey: { type: 'vendors', value: '', desc: this.$t('text.api.vendorKey_desc'), required: true },
        gameKey: { type: 'games', value: '', desc: this.$t('text.api.gameKey_desc'), required: true },
        lobbyKey: {type: 'text', value: 'top_games', desc: this.$t('text.api.lobbyKey_desc')},
        username: { type: 'text', value: 'user1', desc: this.$t('text.api.username_desc'), required: true, length: '1~21' },
        siteUsername: { type: 'text', value: 'user1', desc: this.$t('text.api.siteUsername_desc'), required: true, length: '1~21' },
        nickname: { type: 'text', value: '회원1', desc: this.$t('nickname'), length: '1~21' },
        groupKey: { type: 'text', value: 'shop1', desc: this.$t('text.api.groupKey_desc'), length: '1~32' },
        platform: { type: 'list', list: ['WEB', 'MOBILE'], value: 'WEB', desc: this.$t('text.api.platform_desc') },
        amount: { type: 'number', value: '0', desc: this.$t('text.api.amount_desc') },
        language: {type: 'list', list: ['ko','en'], value: 'ko', desc: '게임 언어'},
      }
    };
  },
  mounted() {
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }

     */
    const host = process.env.VUE_APP_API_SERVER_URL
    this.apiUrl = host + 'play'

    // 응답값 설정
    this.response = '{\n' +
        ' "code": 0, \t\t\t\t\t// 응답값(0이 아니면 오류)\n' +
        ' "url": "https://solawins-sg0.prerelease-env.biz/gs2c/playGame.do?key=token%3DXXXXXXXXXXXXXXX%26symbol%3Dvs20doghouse%26platform%3DWEB%26language%3Dko%26currency%3DKRW%26cashierUrl%3D%26lobbyUrl%3D&stylename=XXXXXXXX&country=KR&minimode=0", // 게임 실행 URL\n' +
        ' "balance": 0, \t\t\t\t\t// 잔액\n' +
        ' "amount": 0 \t\t\t\t\t// 처리금액\n' +
        '}'
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title"><span class="badge bg-primary">요청 URL</span> {{ apiUrl }}</div>
            <div class="card-title-desc">{{ $t('text.api_play_desc')}}</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="true" :require-ip-address="true" :require-request-key="true" :require-play="true" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
